import { IMakePayment, IPayment } from '@app/types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PaymentInfo from '../payment-info.component';
import {
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";

interface IProps {
  payment: IPayment;
  onMakePayment: (payment: IMakePayment) => void;
}

class CheckoutForm extends Component<IProps> {
  submit = () => {
    this.props.onMakePayment({
      paymentId: this.props.payment.id,
      paymentMethodName: this.props.payment.paymentMethod.name,
      stripe: (this.props as any).stripe,
      elements: (this.props as any).elements
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1">Choose Payment Method</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="card card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Card Number</label>
                    <CardNumberElement className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Expiry Date</label>
                    <CardExpiryElement className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>CVV</label>
                    <CardCvcElement className="form-control" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="offset-md-6 col-md-6 text-right">
                  <Button onClick={this.submit}>Create</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <PaymentInfo payment={this.props.payment} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const InjectedCheckoutForm = (rootProps: any) => (
  <ElementsConsumer>
    {(props) => <CheckoutForm {...(props as any)} {...rootProps} />}
  </ElementsConsumer>
);

export default InjectedCheckoutForm;
