import { IAppState } from '@app/store';
import { IFranchisor, IMakePayment, IPayment, IPaymentMethod } from '@app/types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import BookingSummary from '../booking-summary/booking-summary.component';
import { selectState } from '../workshop-enrolment.selectors';
import PaymentMethods from '../../../shared-components/payment-methods/payment-methods.component';
import { Dispatch } from 'redux';
import { fetchPaymentMethods, selectPaymentMethod, makePayment } from '../workshop-enrolment.actions';
import PaymentGateway from '../../../shared-components/payment-gateway/payment-gateway.component';
import { selectFranchisor } from '@app/selectors/auth';

interface IDispatchProps {
  selectPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  loadPaymentMethods: () => void;
  makePayment: (payment: IMakePayment) => void;
}

interface IStateProps {
  payment: IPayment;
  paymentMethods: IPaymentMethod[];
  selectedPaymentMethod: IPaymentMethod;
  paymentMethodLoading: boolean;
  franchisor: IFranchisor;
}

class StepPayment extends React.Component<IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.loadPaymentMethods();
  }

  render() {
    const { payment, paymentMethods, paymentMethodLoading, selectedPaymentMethod, franchisor } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Confirm booking - Workshop Enrolments - Booking Autopilot</title>
        </Helmet>
        <BookingSummary />
        {Boolean(payment) && <PaymentMethods
          paymentMethods={paymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          paymentMethodLoading={paymentMethodLoading}
          onSelectPaymentMethod={this.props.selectPaymentMethod} />}
        <PaymentGateway
          payment={payment}
          selectedPaymentMethod={selectedPaymentMethod}
          currency={franchisor.currency ? franchisor.currency.code : ''}
          onMakePayment={this.props.makePayment}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  selectPaymentMethod: (paymentMethod) => dispatch(selectPaymentMethod(paymentMethod)),
  loadPaymentMethods: () => dispatch(fetchPaymentMethods()),
  makePayment: (payment) => dispatch(makePayment(payment))
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state).screens.bookingSummary;
  return {
    payment: selectState(state).payment,
    paymentMethods: screenState.paymentMethods,
    selectedPaymentMethod: selectState(state).selectedPaymentMethod,
    paymentMethodLoading: screenState.paymentMethodLoading,
    franchisor: selectFranchisor(state)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(StepPayment);
