import { version } from '../../package.json';

const Config = {
  get PUBLIC_URL() {
    return process.env.PUBLIC_URL;
  },
  get SERVER_URL() {
    return process.env.REACT_APP_SERVER_URL;
  },
  get AUTH_SERVER_URL() {
    return process.env.REACT_APP_AUTH_SERVER_URL;
  },
  get NODE_ENV() {
    return process.env.NODE_ENV;
  },
  get DEBUG() {
    return process.env.NODE_ENV !== 'production';
  },
  get GA_TRACKING_ID() {
    return process.env.REACT_APP_GA_TRACKING_ID;
  },
  get SENTRY_URL() {
    return process.env.REACT_APP_SENTRY_URL;
  },
  get VERSION() {
    return 'v' + version;
  },
  get FOOTER_COPYRIGHT() {
    return new Date().getFullYear() + ' © Booking Autopilot - bookingautopilot.com';
  },
};

export default Config;
