import { setGlobalMessageBox } from "@app/actions/root";
import Config from "@app/config";
import httpHelpers from "@app/helpers/http.helpers";
import { Action } from "redux";
import { put, takeLatest } from 'redux-saga/effects';
import { completePaymentSuccess, paymentGatewayFail, paymentGatewaySuccess } from "./payment-callback.actions";
import { PAYMENT_SESSION_KEY } from "./payment-callback.constants";
import history from '@app/helpers/history.helper';

function* paymentGatewaySuccessSaga(action: Action<any>) {
    try {
        if (!sessionStorage.getItem(PAYMENT_SESSION_KEY)) {
            return;
        }

        const paymentId = JSON.parse(sessionStorage.getItem(PAYMENT_SESSION_KEY)).paymentId;
        const processed = JSON.parse(sessionStorage.getItem(PAYMENT_SESSION_KEY)).processed;

        if (!processed) {
            const { paymentStatusId } = yield httpHelpers.post(`${Config.SERVER_URL}/api/booking/process`, {
                paymentId,
            });
            // Stripe payment cancelled after redirect to payment method page
            if (paymentStatusId === 3) {
                yield put(paymentGatewayFail());
                return;
            }
        }

        yield put(completePaymentSuccess());

        const completeUrl = JSON.parse(sessionStorage.getItem(PAYMENT_SESSION_KEY)).completeUrl;
        history.replace(completeUrl);
        sessionStorage.removeItem(PAYMENT_SESSION_KEY);
    } catch (err) {
        yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    }
}

function* paymentGatewayFailSaga(action: Action<any>) {
    try {
        if (!sessionStorage.getItem(PAYMENT_SESSION_KEY)) {
            return;
        }

        const backToUrl = JSON.parse(sessionStorage.getItem(PAYMENT_SESSION_KEY)).backToUrl;
        history.replace(backToUrl);
        sessionStorage.removeItem(PAYMENT_SESSION_KEY);
        yield put(setGlobalMessageBox({ title: 'Payment failed', message: 'Payment is not completed', type: 'error' }));
    } catch (err) {
        yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
    }
}

export default [
    takeLatest(paymentGatewaySuccess.toString(), paymentGatewaySuccessSaga),
    takeLatest(paymentGatewayFail.toString(), paymentGatewayFailSaga)
]