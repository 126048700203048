import * as paymentImages from '@app/assets/images/payments';
import { IPaymentMethod } from '@app/types';
import classNames from 'classnames';
import React from 'react';
import { Badge, Spinner } from 'react-bootstrap';

const getPaymentMethodImage = (method: string) => {
  switch (method.toLowerCase()) {
    case 'easypay':
      return paymentImages.easypay;
    case 'epay':
      return paymentImages.epay;
    case 'paypal':
      return paymentImages.paypal;
    case 'saferpay':
      return paymentImages.saferpay;
    case 'banktransfer':
      return paymentImages.banktransfer;
    case 'instore':
      return paymentImages.instore;
    case 'voucher':
      return paymentImages.voucher;
    case 'gocardless':
      return paymentImages.gocardless;
    case 'stripe':
      return paymentImages.stripe;
    case 'paypalexpress':
      return paymentImages.paypal;
    case 'cloverconnect':
      return paymentImages.clover;
    case 'ponchopay':
      return paymentImages.ponchopay;
  }

  return null;
};

interface IProps {
  onSelectPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  paymentMethods: IPaymentMethod[];
  selectedPaymentMethod: IPaymentMethod;
  paymentMethodLoading: boolean;
}

class PaymentMethods extends React.Component<IProps> {

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  render() {
    const { paymentMethods, selectedPaymentMethod, paymentMethodLoading } = this.props;

    if (paymentMethodLoading) {
      return this.renderLoading();
    }

    return (
      <React.Fragment>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1">Choose Payment Method</h4>
          </div>
        </div>

        <div className="row">
          {paymentMethods.filter(item => item.supportsOneOffPayments).map(item => (
            <div key={String(item.name)} className="col-md-4 col-sm-6">
              <button
                className={classNames(`card flex-row position-relative w-100`, { 'border border-success': Boolean(selectedPaymentMethod) && item.name === selectedPaymentMethod.name })}
                style={{ height: 180 }}
                onClick={() => this.props.onSelectPaymentMethod(item)}
              >
                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex align-items-center justify-content-center" style={{ height: 60 }}>
                    <img src={getPaymentMethodImage(item.name)} alt={item.name} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                  </div>
                  {item.name}
                  <div className="mb-n2 d-flex flex-column justify-content-end" style={{ height: 32 }}>
                    {item.isInstantPayment && <Badge variant="primary">Instant Booking</Badge>}
                  </div>
                </div>
                {(Boolean(selectedPaymentMethod)) && item.name === selectedPaymentMethod.name && (
                  <div className="text-white bg-success rounded-circle d-flex align-items-center justify-content-center position-absolute" style={{ width: 32, height: 32, bottom: -16, left: 'calc(50% - 16px)' }}>
                    <i className="h4 mdi mdi-check m-0"></i>
                  </div>
                )}
              </button>
            </div>
          ))}
        </div>
      </React.Fragment >
    );
  }
}

export default PaymentMethods;
